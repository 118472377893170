<template>
  <div style="min-height: calc(100vh - 93px); background-color: #eaedf1; padding: 1.25vw">
    <div class="top">
      <div class="title">
        已收藏的商品
      </div>
      <!-- <div class="search">
        <el-select v-model="value" size="small" placeholder="请选择" style="margin-right:20px">
          <el-option
            v-for="item in alibabaCategoryList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>

        <a-input-search placeholder="搜素商品" @search="onSearch" />
      </div> -->
    </div>
    <div v-loading="loading">
      <div v-for="(item, index) in tableData" :key="index" class="card">
        <div class="images">
          <img class="imagei" :src="item.image" alt="" srcset="">
        </div>
        <div style="width:60%;flex: 1;">
          <div class="label">
            {{ item.name }}
          </div>
          <div style="margin-left: 16px;">
            价格: ¥{{ item.price }}
          </div>
          <div style="margin-left: 16px;">
            收藏时间: ¥{{ item.collectTime }}
          </div>
        </div>
        <div class="detail-btn">
          <a-button type="primary" @click="toDetails(item)">
            查看详情
          </a-button>
        </div>
      </div>
    </div>
    <el-pagination
      class="fenye"
      background
      :current-page="page.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import Pagination from '../selectionlistcomponent/pagination'
import { getUsercollect } from '@/api/register'
export default {
  mixins: [Pagination],
  data() {
    return {
      value: '1',
      alibabaCategoryList: [{
        label: '全部',
        value: '1'
      }],
      tableData: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    onSearch() {},
    getList() {
      this.loading = true
      getUsercollect(this.page)
        .then((result) => {
          if (result.code === 0) {
            this.loading = false
            this.tableData = result.data.records
            this.page.total = result.data.total
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toDetails(item) {
      if (!item.productId) {
        return
      }
      // 默认是如果当前页面没有选择，-详情查询默认是阿里巴巴
      this.$router.push({ path: '/detailproduct/index', query: { productId: item.productId, selectType: item.supplyType, product: item.price }})
    }
  }
}
</script>

<style lang="scss" scoped>
.top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.title {
  text-align: left;
  font-size: 28px;
  font-weight: 700;
  color: #363d4d;
}
.search{
  display: flex;
  align-items: center;
}
.card {
  background: #fff;
  padding: 12px;
  border-radius: 3px;
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}
.detail-btn{
  align-self: center;
  justify-self: flex-end;
}

.images{
      background-color: #fff;
      width: 60px;
      height: 60px;
      position: relative;
    }
.imagei{
      object-fit: contain;
      object-position: center;
      height: 100%;
      z-index: 100;
    }
.label{
    margin-left: 16px;
    margin-bottom: 0px;
    height: 36px;
    line-height: 18px;
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.fenye {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}
</style>
